import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getCreditInputData,
  getCreditBenchMarkData,
  getCreditRulesData,
} from './CreditScoreCard.actions';
import { loaderModal } from '../../../../store/actions';
import { rupeeDecimalFormatter } from '../../../../utils/utility';

class CreditScoreCard extends Component {
  componentDidMount = async () => {
    let { actions, match } = this.props,
      { userId } = match.params,
      {
        getCreditInputData,
        getCreditBenchMarkData,
        loaderModal,
        getCreditRulesData,
      } = actions,
      token = localStorage.getItem('token');
    loaderModal(true);

    try {
      if (token) {
        await getCreditInputData(userId);
        getCreditBenchMarkData();
        getCreditRulesData(userId);
        actions.loaderModal(false);
      } else {
        let { history } = this.props;
        alert('Unauthorized access');
        loaderModal(false);
        history.push('/');
      }
    } catch {
      actions.loaderModal(false);
    }
  };

  getStatus = (data) => {
    const { creditRulesData } = this.props || {};
    const result =
      creditRulesData?.find(({ name }) => name === data)?.result ?? null;
    if (result === 'true') {
      return <span className='cam_wrapper_result_pass'>Pass</span>;
    } else if (result === 'false') {
      return <span className='cam_wrapper_result_fail'>Fail</span>;
    } else {
      return <span className='cam_wrapper_result_hold'>?</span>;
    }
  };

  render() {
    let { creditBenchMarkData, creditInputData, match } = this.props || {},
      { userId } = match.params;
    return (
      <div className='cam_wrapper container'>
        <Helmet>
          <meta charSet='utf-8' />
          <title> Cam Acquisition</title>
        </Helmet>
        <div className='card'>
          <div className='row m-4'>
            <div className='col-md-3 text-grey'>
              User Id
              <div className='cam_wrapper_heading '>{userId}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Type of customer
              <div className='text-bold'>{creditInputData?.customerType}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Business Initiation
              <div className='text-bold'>
                {creditInputData?.businessInitiation}
              </div>
            </div>
            <div className='col-md-3 text-grey'>
              Dedupe Result
              <div className='text-bold'>
                {creditInputData?.dedupeResult ? (
                  <div className='cam_wrapper_heading '>Pass</div>
                ) : (
                  <div className='cam_wrapper_heading '>Fail</div>
                )}
              </div>
            </div>
          </div>

          <table className='m-5'>
            <thead>
              <th width='10'>Sr No.</th>
              <th width='250'>Parameter</th>
              <th width='100'>Input</th>
              <th width='150'>Benchmark</th>
              <th width='50'>Pass/Fail</th>
            </thead>
            <tbody>
              <tr className='cam_wrapper_border'>
                <th colSpan='5' className='cam_wrapper_heading border_white'>
                  Business Detail
                </th>
              </tr>
              <tr>
                <td>1</td>
                <td>Promoter Age</td>
                <td>{creditInputData?.promoterAge ?? '-'}</td>
                <td>{creditBenchMarkData?.promoterAge}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('promoterAge')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Limit Amount</td>
                <td>
                  {rupeeDecimalFormatter(creditInputData?.limitAmount, '₹') ||
                    '-'}
                </td>
                <td>{creditBenchMarkData?.limitAmount}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('limitAmount')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Customer Turnover</td>
                <td>
                  {rupeeDecimalFormatter(
                    creditInputData?.customerTurnover,
                    '₹'
                  ) ?? '-'}
                </td>
                <td>{creditBenchMarkData?.customerTurnover}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('customerTurnover')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>No of Min Balance Charges in last 12 Months</td>
                <td>{creditInputData?.numberOfMinBalanceCharges ?? '-'}</td>
                <td>{creditBenchMarkData?.numberOfMinBalanceCharges}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('numberOfMinBalanceCharges')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>No of EMI Bouncing in last 3 Months</td>
                <td>
                  {creditInputData?.numberOfEmiBouncingInLast3Months ?? '-'}
                </td>
                <td>{creditBenchMarkData?.numberOfEmiBouncingInLast3Months}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('numberOfEmiBouncingInLast3Months')}
                  </div>
                </td>
              </tr>
              <tr className='cam_wrapper_border'>
                <th colspan='5' className='cam_wrapper_heading '>
                  Credit Behavior
                </th>
              </tr>
              <tr>
                <td>6</td>
                <td>No of EMI bouncing in last 6 months</td>
                <td>
                  {creditInputData?.numberOfEmiBouncingInLast6Months ?? '-'}
                </td>
                <td>{creditBenchMarkData?.numberOfEmiBouncingInLast6Months}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('numberOfEmiBouncingInLast6Months')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>ABB ( Average Daily Balance )</td>
                <td>
                  {rupeeDecimalFormatter(
                    creditInputData?.averageDailyBalance,
                    '₹'
                  ) ?? '-'}
                </td>
                <td>{creditBenchMarkData?.averageDailyBalance}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('averageDailyBalance')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>Current Bucket Ageing</td>
                <td>{creditInputData?.currentBucketAgeing ?? '-'}</td>
                <td>{creditBenchMarkData?.currentBucketAgeing}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('currentBucketAgeing')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>Peak Ageing in Last 6 Months</td>
                <td>{creditInputData?.peakAgeingInLast6Months ?? '-'}</td>
                <td>{creditBenchMarkData?.peakAgeingInLast6Months}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('peakAgeingInLast6Months')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>No. of Instances in Last 12 Months Ageing &lt; 30</td>
                <td>{creditInputData?.instancesOfAgeing ?? '-'}</td>
                <td>{creditBenchMarkData?.instancesOfAgeing}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('instancesOfAgeing')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>11</td>
                <td>Peak Ageing in last 12 Months &gt; 30</td>
                <td>{creditInputData?.peakAgeingInLast12Months ?? '-'}</td>
                <td>{creditBenchMarkData?.peakAgeingInLast12Months}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('peakAgeingInLast12Months')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>12</td>
                <td>Unsecured Loans Taken &lt; 12 Months</td>
                <td>{creditInputData?.unsecuredLoans ?? '-'}</td>
                <td>{creditBenchMarkData?.unsecuredLoans}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('unsecuredLoans')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>13</td>
                <td>Imputed EMI</td>
                <td>{creditInputData?.imputedEmi ?? '-'}</td>
                <td>{creditBenchMarkData?.imputedEmi}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('imputedEmi')}
                  </div>
                </td>
              </tr>
              <tr className='cam_wrapper_border'>
                <th colspan='5' className='cam_wrapper_heading'>
                  Credit Behavior
                </th>
              </tr>

              <tr>
                <td>14</td>
                <td>No. of Live Loans (Other than GL, CD, TW) </td>
                <td>{creditInputData?.liveLoans ?? '-'}</td>
                <td>{creditBenchMarkData?.liveLoans}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('liveLoans')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>15</td>
                <td>Write Off</td>
                <td>
                  {rupeeDecimalFormatter(creditInputData?.writeOff, '₹') ?? '-'}
                </td>
                <td>{creditBenchMarkData?.writeOff}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('writeOff')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>16</td>
                <td>Bureau Score</td>
                <td>{creditInputData?.bureauScore ?? '-'}</td>
                <td>{creditBenchMarkData?.bureauScore}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('bureauScore')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>17</td>
                <td>Business Stablity</td>
                <td>{creditInputData?.businessStability ?? '-'}</td>
                <td>{creditBenchMarkData?.businessStability}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('businessStability')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>18</td>
                <td>Neighbour reference Check</td>
                <td>{creditInputData?.neighbourReferenceCheck ?? '-'}</td>
                <td>{creditBenchMarkData?.neighbourReferenceCheck}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('neighbourReferenceCheck')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>19</td>
                <td>Supplier reference Check</td>
                <td>{creditInputData?.supplierReferenceCheck ?? '-'}</td>
                <td>{creditBenchMarkData?.supplierReferenceCheck}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('supplierReferenceCheck')}
                  </div>
                </td>
              </tr>
              <tr className='cam_wrapper_border'>
                <th colspan='5' className='cam_wrapper_heading'>
                  Assets - Liabilities Profile
                </th>
              </tr>
              <tr>
                <td>20</td>
                <td>House / Business Permises Owned</td>
                <td>{creditInputData?.premisesOwned ? 'Yes' : 'No'}</td>
                <td>{creditBenchMarkData?.premisesOwned}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('premisesOwned')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>21</td>
                <td>Relationship With Supplier</td>
                <td>{creditInputData?.relationshipWithSupplier ?? '-'}</td>
                <td>{creditBenchMarkData?.relationshipWithSupplier}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('relationshipWithSupplier')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>22</td>
                <td>Repayment to Supplier within Days</td>
                <td>{creditInputData?.repaymentToSupplierWithinDays ?? '-'}</td>
                <td>{creditBenchMarkData?.repaymentToSupplierWithinDays}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('repaymentToSupplierWithinDays')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>23</td>
                <td>No. of Orders in a year with Supplier</td>
                <td>{creditInputData?.numberOfOrders ?? '-'}</td>
                <td>{creditBenchMarkData?.numberOfOrders}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('numberOfOrders')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>24</td>
                <td>Total Value of Asset Owned</td>
                <td>
                  {rupeeDecimalFormatter(
                    creditInputData?.totalValueAssetOwned,
                    '₹'
                  ) ?? '-'}
                </td>
                <td>{creditBenchMarkData?.totalValueAssetOwned}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('totalValueAssetOwned')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>25</td>
                <td>Dependents</td>
                <td>{creditInputData?.dependents ?? '-'}</td>
                <td>{creditBenchMarkData?.dependents}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('dependents')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>26</td>
                <td>Insurance Premium Paid in Last 12 months</td>
                <td>
                  {rupeeDecimalFormatter(
                    creditInputData?.insurancePremiumPaid,
                    '₹'
                  ) ?? '-'}
                </td>
                <td>{creditBenchMarkData?.insurancePremiumPaid}</td>
                <td>
                  <div className='cam_wrapper_result'>
                    {this.getStatus('insurancePremiumPaid')}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ creditScoreReducer }) => ({
  creditInputData: creditScoreReducer.creditInputData,
  creditBenchMarkData: creditScoreReducer.creditBenchMarkData,
  creditRulesData: creditScoreReducer.creditRulesData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCreditInputData,
      getCreditBenchMarkData,
      loaderModal,
      getCreditRulesData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditScoreCard);
