import React from 'react';
import AppCard from '../../../../../components/AppCard';

const RulesCard = props => {
  const { data } = props;

  const headingRules = element => {
    if (element === 'liveLoans') {
      return 'No of Live Loans(other than GL,CD,TW)';
    } else if (element === 'currentBucketAgeing') {
      return 'Current Bucket Agening';
    } else if (element === 'peakAgeingInLast6Months') {
      return ' Last 6 month Ageing';
    } else if (element === 'instancesOfAgeing') {
      return `No of instances in Last 12 Months Ageing < 30`;
    } else if (element === 'peakAgeingInLast12Months') {
      return 'Peak Ageing in Last 12 Months';
    } else if (element === 'writeOff') {
      return ' Write off';
    } else if (element === 'bureauScore') {
      return 'Bureau Score';
    } else if (element === 'unsecuredLoans') {
      return 'Last Unsecured loan taken < 12 month';
    }
  };

  return (
    <>
      <AppCard className='rules-wrapper' heading='Rules'>
        <div className='rules-wrapper row'>
          {data?.map((ele, i) => {
            return (
              <>
                <div
                  key={i}
                  className={`rules-wrapper-heading ${
                    ele?.result === 'true' ? '' : 'red'
                  }  col-4`}
                >
                  {headingRules(ele?.name)}
                  <div className={`${ele.result === 'true' ? '' : 'sub-red'}`}>
                    {ele?.value}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </AppCard>
    </>
  );
};
export default RulesCard;
